<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/Mechanism/agencyCertification">机构认证审核 / </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </span>
      </div>
    </div>
    <!-- 机构 -->
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name">机构信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>机构名称：<span>{{form.orgName}}</span></p>
            <p>机构码：<span>{{form.orgCode}}</span></p>
            <p>机构邮箱：<span>{{form.email}}</span></p>
          </div>
          <div class="line_item">
            <p>企业类型：<span>{{form.orgType == 1 ? '企业单位' : '非企业单位'}}</span></p>
            <p>地区：
              <span v-if="form.orgProvice">{{form.orgProvice + '-' + form.orgCity + '-' + form.orgArea}}</span>
              <span v-else>-</span>
            </p>
            <p>是否为定点机构：<span>{{form.appointedFlag ? '是' : '否'}}</span></p>
          </div>
          <div class="line_item">
            <p>企业规模：<span>{{form.dictionaryValue}}</span></p>
            <p>所在行业：<span>{{form.industry}}-{{form.industrySub}}</span></p>
            <p v-show="form.orgType == 1">统一社会信用代码：<span>{{form.institutionCode}}</span></p>
          </div>
          <div class="line_item">
            <p>营业执照/事业单位法人证书：
              <viewer v-if="form.businessPhoto">
                <img :src="form.businessPhoto" alt="">
              </viewer>
            </p>
            <p>一般纳税人资格证明：
              <viewer v-if="form.taxpayerPhoto">
                <img :src="form.taxpayerPhoto" alt="">
              </viewer>
            </p>
          </div>
        </div>
      </div>

      <div class="Up_Down_inner">
        <span class="all_left_name">机构联系人</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>姓名：<span>{{form.contactName}}</span></p>
            <p>证件信息：<span>{{form.contactIdcard}}</span></p>
            <p>手机号：<span>{{form.contactTell}}</span></p>
          </div>
          <div class="line_item">
            <p>机构职位：<span>{{form.contactPost}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <a-button class="btn" v-show="this.form.auditStatus == 1" type="primary" @click="openModal(2)" :loading="loading" v-hasPermi="['org:audit:updatestatus']">审核</a-button>
      <a-button class="btn" v-show="this.form.auditStatus == 1" type="primary" @click="openModal(3)" :loading="loading" v-hasPermi="['org:audit:updatestatus']">驳回</a-button>
      <a-button class="btn" @click="$router.go(-1)">返回</a-button>
    </div>

    <!-- 审核 -->
    <a-modal v-model="isCheckShow" title="提示">
      <div class="all_content_box">
        <p class="tips">{{tips}}</p>
        <a-row class="a-row-item" v-show="auditStatus == 3">
          <a-col :span="4" class="a-row-left"
            ><span class="name">驳回原因：</span></a-col>
          <a-col>
            <a-textarea
              style="width: 80%"
              placeholder="请输入驳回原因"
              v-model="auditContent"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onOk">确认</a-button>
        <a-button @click="isCheckShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      form:{},
      loading: false,
      isCheckShow: false,
      auditStatus: 0,
      auditContent: '',
      tips: ''
    }
  },
  // 事件处理器
  methods: { 
    openModal(type){
      this.auditStatus = type
      // type 2.审核  3.驳回
      if(type == 2){
        this.tips='是否确认审核通过该机构提交的认证'
      } else if(type == 3) {
        this.tips='是否确认驳回该机构提交的认证'
      }
      this.isCheckShow = true
    },

    onOk(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/organization/info/audit',
        method: 'put',
        params: {
          orgId: this.form.id,
          auditStatus: this.auditStatus,
          auditContent: this.auditStatus == 3 ? this.auditContent : '',
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success('操作成功')
          this.$router.go(-1)
        }else{
          this.$message.warning(res.message)
        }
      });
    },

    // 获取数据
    getDetail() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/organization/info/certification/details?userId=" + this.$route.query.userId,
      }).then((res) => {
        this.loading = false
        if(res.code == 200 && res.success){
          this.form = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getDetail()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
}
.all_main_wrap{
  padding: 20px 16px;
}
.table-box{
  width: 800px;
}
.bgDiv{
  .line_item{
    display: flex;
    margin-bottom: 24px;
    p{
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span{
        font-weight: 500;
        color: #333333;
      }
      img{
        margin-top: 10px;
        display: block;
        width: 230px;
        cursor: pointer;
      }
    }
  }
  .line_item:last-child{
    margin-bottom: 0;
  }
}
.button{
  margin-top: 40px;
  .btn{
    margin-right: 15px;
  }
}

/deep/ .ant-modal-body{
  padding: 10px 24px;
}
.all_content_box {
  .tips{
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
    text-align: center;
  }
  .a-row-item {
    margin-bottom: 20px;
    .name {
      width: 100%;
      display: inline-block;
      text-align: left;
    }
    .right {
      width: 100%;
    }
  }
}
</style>
